import React, { useRef } from 'react';
import { css } from 'glamor';
import { View, Text, P, HeaderText, Title } from '../components/common';
import Layout from '../components/layout';
import Content, { SMALL, MEDIUM, LARGE } from '../components/content';
import useVisibility from '../useVisibility';
import WhenVisible from '../components/WhenVisible';

import TopBackground from '../images/top-background.svg';

function FullFeature({ title, media, opposite, children }) {
  let asset = useRef(null);
  let { ref, style } = useVisibility({
    onVisible: () => {
      if (asset.current) {
        if (media.video) {
          let video = asset.current.querySelector('video');
          video.play();
        }
      }
    },
  });

  return (
    <View
      className={
        'w-full mt-32 md:px-12 items-center flex-col-reverse text-center ' +
        (opposite
          ? 'md:flex-row-reverse md:text-right'
          : 'md:flex-row md:text-left')
      }
      style={style}
      ref={ref}
    >
      <View className={`mt-12 md:mt-0 ${opposite ? 'md:ml-16' : 'md:mr-16'}`}>
        <HeaderText>{title}</HeaderText>
        <div className="mt-4">{children}</div>
      </View>

      <View className="flex-shrink-0">
        {media.image ? (
          <img
            ref={asset}
            src={media.image}
            style={{ width: media.width || 350 }}
          />
        ) : (
          <div
            ref={asset}
            dangerouslySetInnerHTML={{
              __html: `
                  <video
                    loop
                    muted
                    autoplay
                    playsinline
                    src="${media.video}"
                  >
                 </video>
                `,
            }}
            style={{ width: 350 }}
          />
        )}
      </View>
    </View>
  );
}

function ScreenshotFeature({
  title,
  id,
  className,
  direction,
  desktopImage,
  mobileImage,
  children,
}) {
  let { ref, style } = useVisibility({
    direction,
    threshold: 0.4,
    disableIfSmall: true,
  });

  return (
    <View
      ref={ref}
      className={'text-center mt-40 ' + className}
      style={[{ width: 700, [SMALL]: { width: 'auto' } }, style]}
      id={id}
    >
      <HeaderText>{title}</HeaderText>
      <div className="mt-8">{children}</div>

      <View className="mt-12 flex-col md:flex-row relative items-center justify-center">
        <img className="shadow-lg" src={desktopImage} style={{ width: 620 }} />

        {mobileImage && (
          <img
            className="shadow-lg rounded-lg md:ml-12 mt-8 md:mt-0"
            src={mobileImage}
            {...css({
              width: 180,
              [MEDIUM]: { width: '60%' },
              [SMALL]: { width: '80%' },
            })}
          />
        )}
      </View>
    </View>
  );
}

function Feature({ icon: Icon, title, children }) {
  return (
    <div
      className="mb-8 md:mb-0 link-color-inherit"
      style={{ paddingLeft: 40, flex: 1 }}
    >
      <div className="mb-4 relative">
        <Icon
          style={{
            width: 24,
            fill: 'white',
            position: 'absolute',
            top: 0,
            left: -40,
          }}
        />
        <strong>{title}</strong>
      </div>
      <div>{children}</div>
    </div>
  );
}

function FeatureRow({ children }) {
  let [left, right] = children;
  return (
    <View className="md:mb-16 flex-col md:flex-row w-full">
      {left}
      <div className="md:pl-16" />
      {right}
    </View>
  );
}

let IndexPage = () => (
  <Layout
    flushFooter
    style={{
      background: 'linear-gradient(180deg, white 500px, #f7f8fa 600px)',
    }}
  >
    <img
      {...css({
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: 900,
        [MEDIUM]: { height: 700 },
      })}
      src={TopBackground}
    />
    <Content
      style={{
        paddingBottom: 100,
        alignItems: 'center',
      }}
    >
      <WhenVisible animations={['opacity']}>
        <View
          className="items-center  md:px-12 sm:px-0 mt-24 md:mt-32"
          style={{
            width: 850,
            [MEDIUM]: { width: '100%' },
            '& ul li': {
              listStyle: 'disc',
            },
          }}
        >
          <HeaderText textSize="" className="text-3xl text-gray-800">
            From hosted Actual to open-source
          </HeaderText>

          <Text className="mx-4 md:mx-8 mt-12" style={{ fontSize: 17 }}>
            <P>
              Many of you have supported Actual for a long time. This means so
              much to me. I put my heart and soul into this product for several
              years, and I've learned a ton over the years. This was my first
              product I launched to the world, and I would do so many things
              differently now. Even so it makes me happy that many of you found
              value in what I built.
            </P>
            <P>
              In 2022 it became clear that this ambitious project was
              too difficult to run myself. Instead of shutting it down, I{' '}
              <a href="http://open-source.actualbudget.com">open-sourced it</a>{' '}
              and handed it over to the community. It's now an actively
              developed open-source project thanks to other contributors.
            </P>
            <P>
              The hosted version of Actual will be shut down November 15th,
              2024. The hosted server will be turned off. Since Actual is a
              local-first app, you should still have access to your data but it
              won't sync.
            </P>
            <P>
              I recommend exporting your data now because once the hosted
              servers are shutdown, you won't be able to login. If you don't
              already have a local copy, you won't be able to access your data.
              Please <a href="mailto:longster@gmail.com">email me</a> if you
              need access to your data after the shutdown and I'll see if I can
              help.
            </P>
            <P>
              - <a href="https://x.com/jlongster">James Long</a>
            </P>

            <View
              className="text-gray-700"
              style={{ fontSize: 20, marginTop: 60 }}
            >
              Migrating to the open-source version
            </View>
            <P>
              If you want to continue using Actual, you can host the{' '}
              <a href="https://actualbudget.org">
                open-source version
              </a>{' '}
              yourself! It's really not that hard and the community is very
              helpful.
            </P>

            <P>Once you have it running, you'll need to migrate your data:</P>

            <P>
              <ul>
                <li>
                  The desktop app does not support the full export<sup>*</sup>.
                  Open your budget on{' '}
                  <a href="http://app.actualbudget.com">the webapp</a>.
                </li>
                <li>
                  Open settings by clicking the 3-dots in the top left and
                  selecting "Settings".
                </li>
                <li>Under "Export" click "Export data"</li>
                <li>
                  After setting up the open-source version, follow{' '}
                  <a href="https://actualbudget.github.io/docs/Getting-Started/migration/actual-import">
                    these instructions
                  </a>{' '}
                  for importing your data.
                </li>
              </ul>
            </P>

            <P style={{ fontStyle: 'italic' }}>
              <sup>*</sup> If needed, it is possible to generate an importable
              Actual zip file from the local files if you only used the desktop
              app. You just need to zip up the <code>db.sqlite</code> and{' '}
              <code>metadata.json</code> files together. Find these files inside
              the budget dir in your local <code>Actual</code> directory
              (probably in Documents).
            </P>
          </Text>
        </View>
      </WhenVisible>
    </Content>
  </Layout>
);

export default IndexPage;
